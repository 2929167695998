import ModuleText from "../components/modules/ModuleText"
import ModuleHero from "../components/modules/ModuleHero"
import ModuleForm from "components/modules/ModuleForm"
import ModulePromo from "../components/modules/ModulePromo"
import ModuleTable from "components/modules/ModuleTable"
import ModuleNotFound from "../components/modules/ModuleNotFound"
import ModuleGuideList from "../components/modules/ModuleGuideList"
import ModuleImageList from "components/modules/ModuleImageList"
import ModuleHeroSlider from "../components/modules/ModuleHeroSlider"
import ModulePersonList from "../components/modules/ModulePersonList"
import ModulePodcastList from "../components/modules/ModulePodcastList"
import ModuleHistoryList from "components/modules/ModuleHistoryList"
import ModulePricingTable from "components/modules/ModulePricingTable"
import ModuleTeaserGuides from "../components/modules/ModuleTeaserGuides"
import ModuleContactPerson from "components/modules/ModuleContactPerson"
import ModuleTeaserPodcast from "components/modules/ModuleTeaserPodcast"
import ModuleTeaserArticles from "../components/modules/ModuleTeaserArticles"
import ModuleImageCaroussel from "components/modules/ModuleImageCaroussel"
import ModuleBenefitsRequest from "components/modules/ModuleBenefitsRequest"
import ModuleHighlightNumbers from "components/modules/ModuleHighlightNumbers"
import ModuleTeaserInitiatives from "components/modules/ModuleTeaserInitiatives"
import ModuleTeaserPressrelease from "components/modules/ModuleTeaserPressrelease"
import ModuleArticleList from "components/modules/ModuleArticleList"

export default {
    moduleText: ModuleText,
    moduleHero: ModuleHero,
    moduleForm: ModuleForm,
    modulePromo: ModulePromo,
    moduleTable: ModuleTable,
    moduleNotFound: ModuleNotFound,
    moduleImageList: ModuleImageList,
    moduleGuideList: ModuleGuideList,
    moduleHeroSlider: ModuleHeroSlider,
    modulePersonList: ModulePersonList,
    modulePodcastList: ModulePodcastList,
    moduleHistoryList: ModuleHistoryList,
    moduleArticleList: ModuleArticleList,
    moduleTeaserGuides: ModuleTeaserGuides,
    modulePricingTable: ModulePricingTable,
    moduleTeaserPodcast: ModuleTeaserPodcast,
    moduleContactPerson: ModuleContactPerson,
    moduleTeaserArticles: ModuleTeaserArticles,
    moduleImageCaroussel: ModuleImageCaroussel,
    moduleBenefitsRequest: ModuleBenefitsRequest,
    moduleHighlightNumbers: ModuleHighlightNumbers,
    moduleTeaserInitiatives: ModuleTeaserInitiatives,
    moduleTeaserPressrelease: ModuleTeaserPressrelease,
}
