import * as Sentry from "@sentry/react"
import dynamic from "next/dynamic"
import ErrorFallback from "./ErrorFallback"

const AnimatedModuleRenderer = dynamic(() => import("./AnimatedModuleRenderer"))

export default function ModuleRenderer({
    page = {},
    modules = [],
    components = {},
    preview = false,
}) {
    // if (preview) {
    //   return <AnimatedModuleRenderer {...{ modules, components, preview }} />
    // }

    return (
        <>
            {modules?.map((module) => {
                const Component =
                    components[module._type] || components["moduleNotFound"]

                return (
                    <Sentry.ErrorBoundary
                        fallback={ErrorFallback(module)}
                        key={module._key}>
                        <Component module={module} page={page} />
                    </Sentry.ErrorBoundary>
                )
            })}
        </>
    )
}
